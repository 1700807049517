.img-bg .reward {
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background: transparent;
}
.img-bg span.insdex {
  color: #e13b3f;
  font-weight: 800;
  /* font-size: 19px; */
}
.img-bg .Card-Text {
  font-size: 13px;
  line-height: 1.35;
  text-align: center;
}
.img-bg .check-boexs1.cen {
  background-image: linear-gradient(45deg, #6303b1, #ff0099);
}

.img-bg .check-boexs1.cen {
  color: white;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #cccccca3 10px,
    #ccc0 20px
  );
}
.img-bg p.CardDesc {
  font-weight: 600;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 1rem;
}
.img-bg .check-boexs1.cen {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}
.img-bg button.card_selection {
  width: 85%;
  display: flex;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  color: #fff !important;
  padding: 5px 8px;
  border-radius: 30px;
  border: 1px solid #004681;
  background: #004681;
  text-align: center;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none !important;
}
