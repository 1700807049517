.img-bg .reward input[type="radio"] {
  margin: 0px !important;
}
.img-bg .reward input[type="radio"] {
  width: 100%;
  outline: none;
  margin-bottom: 2px;
}
.img-bg label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
}
.img-bg form.form-outer label {
  width: 100%;
  text-transform: inherit;
}
.img-bg form.form-outer label span.insdex {
  display: block;
}

.img-bg form.form-outer label .pirds {
  width: 100%;
  height: 95px;
  margin-top: 10px;
}

.img-bg .reward img {
  width: 100%;
  /* height: 95px;
  margin-top: 10px;
  object-fit: contain; */
}
.img-bg .reward label {
  min-height: 64px !important;
}
.img-bg .imgcolor {
  margin-top: 10px;
  width: 100%;
  border-radius: 6px;
  margin-right: 8px;
}
.img-bg .carousel-inner > .item > a > img,
.img-bg .carousel-inner > .item > img,
.img-bg .img-responsive,
.img-bg .thumbnail a > img,
.img-bg .thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
}
